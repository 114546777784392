import { type Strategy } from 'remix-auth'
import { z } from 'zod'
import { type Timings } from '#server/timing.server.ts'

export type ProviderAccess = {
  accessToken?: string
  refreshToken?: string
  expiresIn?: number
}

// Define a user type for cleaner typing
export type ProviderUser = {
  id: string
  email?: string
  emailVerified?: Date
  username?: string
  name?: string
  avatarUrl?: string
} & ProviderAccess

export interface AuthProvider {
  getAuthStrategy(request: Request): Strategy<ProviderUser, any>
  handleMockAction(request: Request): Promise<void>
  resolveConnectionData(
    providerId: string,
    options?: { timings?: Timings },
  ): Promise<{
    displayName: string
    link?: string | null
  }>
}

export const GOOGLE_PROVIDER_NAME = 'google'
export const INSTAGRAM_PROVIDER_NAME = 'instagram'
export const TIKTOK_PROVIDER_NAME = 'tiktok'

export const providerNames = [
  GOOGLE_PROVIDER_NAME,
  // INSTAGRAM_PROVIDER_NAME,
  // TIKTOK_PROVIDER_NAME,
] as const
export const ProviderNameSchema = z.enum(providerNames)
export type ProviderName = z.infer<typeof ProviderNameSchema>
