import { Form } from '@remix-run/react'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import { useIsPending } from '#app/utils/misc.tsx'
import {
  type ProviderName,
  GOOGLE_PROVIDER_NAME,
  INSTAGRAM_PROVIDER_NAME,
  TIKTOK_PROVIDER_NAME,
} from '#server/auth/providers/provider.ts'

export const providerLabels: Record<ProviderName, string> = {
  [GOOGLE_PROVIDER_NAME]: 'Google',
  // [INSTAGRAM_PROVIDER_NAME]: 'Instagram',
  // [TIKTOK_PROVIDER_NAME]: 'TikTok',
} as const

export const providerIcons: Record<ProviderName, React.ReactNode> = {
  [GOOGLE_PROVIDER_NAME]: (
    <img className="h-7 w-7" src="/app/assets/google-g-logo.svg" alt="" />
  ),
  // [INSTAGRAM_PROVIDER_NAME]: (
  //   <img className="h-7 w-7" src="/app/assets/instagram-logo.svg" alt="" />
  // ),
  // [TIKTOK_PROVIDER_NAME]: <img className="h-7 w-7" src="/app/assets/tiktok-dark-logo.svg" alt="" />,
} as const

export function ProviderConnectionForm({
  redirectTo,
  type,
  providerName,
}: {
  redirectTo?: string | null
  type: 'Continue' | 'Connect' | 'Login' | 'Signup'
  providerName: ProviderName
}) {
  const label = providerLabels[providerName]
  const formAction = `/auth/${providerName}`
  const isPending = useIsPending({ formAction })
  return (
    <Form
      className="flex items-center justify-center gap-2"
      action={formAction}
      method="POST"
    >
      {redirectTo ? (
        <input type="hidden" name="redirectTo" value={redirectTo} />
      ) : null}
      <StatusButton
        type="submit"
        className="text-body-sm w-full py-6 font-semibold"
        variant="outline"
        status={isPending ? 'pending' : 'idle'}
      >
        <span className="inline-flex items-center">
          <span className="mr-2">{providerIcons[providerName]}</span>
          <span className="mt-0.5">
            {type} with {label}
          </span>
        </span>
      </StatusButton>
    </Form>
  )
}
